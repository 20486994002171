/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 
// Fonts
$primary-font: IBMPlexSans, sans-serif;

// Font Weights
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Functions
@function fontSize($size) {
    @return ($size) * 1.05;
}

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-Regular), url(/assets/fonts/IBMPlexSans-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-Medium), url(/assets/fonts/IBMPlexSans-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-SemiBold), url(/assets/fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-Bold), url(/assets/fonts/IBMPlexSans-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

.H1LeftBlack {
    font-family: $primary-font;
    font-size: 28px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--black);
}

.H1Dark-GreyLeft {
    font-family: $primary-font;
    font-size: 28px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--dark-grey);
}

.H1BlackLeft {
    font-family: $primary-font;
    font-size: 28px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--black);
}

.H2Dark-GreyLeft {
    font-family: $primary-font;
    font-size: 24px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--dark-grey);
}

.H2BlackLeft {
    font-family: $primary-font;
    font-size: 24px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--black);
}

.H2BlackCenter {
    font-family: $primary-font;
    font-size: 24px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--black);
}

.H3BlackLeft {
    font-family: $primary-font;
    font-size: 20px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--black);
}

.BodyWhiteLeft {
    font-family: $primary-font;
    font-size: fontSize(18px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--white);
}

.BodyBlackLeft {
    font-family: $primary-font;
    font-size: fontSize(18px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.SubtextDark-GreyLeft {
    font-family: $primary-font;
    font-size: fontSize(18px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: left;
    color: var(--dark-grey);
}

.SubtextBlackLeft {
    font-family: $primary-font;
    font-size: fontSize(18px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--black);
}

.SubtextBlackCenter {
    font-family: $primary-font;
    font-size: fontSize(18px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--black);
}

.ParagraphWhiteCenter {
    font-family: $primary-font;
    font-size: fontSize(15px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--white);
}

.ParagraphDark-GreyLeft {
    font-family: $primary-font;
    font-size: fontSize(15px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--dark-grey);
}

.ParagraphBlackLeft {
    font-family: $primary-font;
    font-size: fontSize(15px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--black);
}

.ParagraphBlackCenter {
    font-family: $primary-font;
    font-size: fontSize(15px);
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--black);
}

.ParagraphLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--black);
}

.Small-TitleWhiteLeft {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.Small-TitleLight-GreyLeft {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--disabled);
}

.Small-TitleLight-Gr {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--disabled);
}

.Small-TitleDark-GreyLeft {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.Small-TitleBlackLeft {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
}

.Small-TitleBlackCenter {
    font-family: $primary-font;
    font-size: fontSize(14px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.TinyBlackLeft {
    font-family: $primary-font;
    font-size: fontSize(12px);
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.TinyBlackCenter {
    font-family: $primary-font;
    font-size: fontSize(12px);
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.Tiny-RegularDark-GreyCenter {
    font-family: $primary-font;
    font-size: fontSize(12px);
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-grey);
}